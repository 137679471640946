import styled, { keyframes } from 'styled-components';
import Box from '../Box/Box.js';
import { themeGet } from '../../utils/theme-get.js';
import sx from '../../sx.js';

const shimmer = keyframes(["0%{background-position:200% 0}to{background-position:-200% 0}"]);
const Wrapper = styled(Box).withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-47c8xx-0"
})(["position:relative;overflow:hidden;"]);
const AnimatedBox = styled(Box).withConfig({
  displayName: "styles__AnimatedBox",
  componentId: "sc-47c8xx-1"
})(["--fk-skeleton-animated-stop-2:", ";--fk-skeleton-animated-stop-1:", ";display:block;position:relative;border-radius:", ";margin-bottom:8px;width:", ";height:", ";background-color:", ";animation:none;@media (prefers-reduced-motion:no-preference){animation-name:", ";animation-duration:8s;animation-timing-function:ease-in-out;animation-delay:0;animation-iteration-count:infinite;animation-direction:normal;animation-fill-mode:none;animation-play-state:", ";}background-image:linear-gradient( 270deg,var(--fk-skeleton-animated-stop-1),var(--fk-skeleton-animated-stop-2),var(--fk-skeleton-animated-stop-2),var(--fk-skeleton-animated-stop-1) );background-size:400% 100%;", ""], themeGet('colors.skeleton.default'), themeGet('colors.elevation.surface.default.[default]'), p => () => {
  if (p.appearance && p.appearance === 'round') return '50%';
  if (p.appearance && p.appearance === 'square') return '0px!important';
  if (p.appearance && p.appearance === 'pill') return '99999px';
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (p.appearance && p.appearance === 'default') return themeGet('radii.1');
  return themeGet('radii.1');
}, p => p.width ? `${p.width}px` : 'revert', p => () => {
  if (p.appearance && p.width && p.appearance === 'round') return `${p.width}px`;
  if (p.height) return `${p.height}px`;
  return '40px';
}, themeGet('colors.skeleton.default'), shimmer, p => p.animated === false ? 'paused' : 'running', sx);

export { AnimatedBox, Wrapper };
