/**
 * @fuegokit/tokens 0.23.0
 * Do not edit directly
 * Generated on Fri, 11 Oct 2024 13:40:14 GMT
 */
export default {
  scale: {
    orange: {
      "100": "#ffd6c4",
      "200": "#ffad89",
      "300": "#ff996c",
      "400": "#ed7c4a",
      "500": "#be633b",
      "600": "#8e4a2c",
      "700": "#5f321e",
      "800": "#2f190f",
      "900": "#180c07",
      "000": "#fff5f0",
    },
    blue: {
      "100": "#b0caff",
      "200": "#6195ff",
      "300": "#397bff",
      "400": "#336fe6",
      "500": "#2256c5",
      "600": "#224a99",
      "700": "#173166",
      "800": "#0c1933",
      "900": "#070f1f",
      "000": "#ebf2ff",
    },
    teal: {
      "100": "#d1fef9",
      "200": "#a4fdf4",
      "300": "#8dfcf1",
      "400": "#7fe3d9",
      "500": "#71cac1",
      "600": "#559791",
      "700": "#386560",
      "800": "#1c3330",
      "900": "#111e1d",
      "000": "#f4fffe",
    },
    purple: {
      "100": "#ddcbff",
      "200": "#ba97ff",
      "300": "#a97dff",
      "400": "#9871e6",
      "500": "#8764cc",
      "600": "#654b99",
      "700": "#443266",
      "800": "#221933",
      "900": "#140f1f",
      "000": "#f6f2ff",
    },
    white: "#ffffff",
    black: "#1e1e1e",
    yellow: {
      "100": "#fffbe2",
      "200": "#fff8c5",
      "300": "#fff6b6",
      "400": "#ffee70",
      "500": "#f0da4e",
      "600": "#c0ae3e",
      "700": "#90832f",
      "800": "#484117",
      "900": "#181608",
      "000": "#fffef8",
    },
    red: {
      "100": "#fbb4b4",
      "200": "#f66969",
      "300": "#f44343",
      "400": "#dc3c3c",
      "500": "#c33636",
      "600": "#922828",
      "700": "#621b1b",
      "800": "#310e0e",
      "900": "#1d0808",
      "000": "#feecec",
    },
    green: {
      "100": "#b7dfb9",
      "200": "#b7dfb9",
      "300": "#4caf50",
      "400": "#449e48",
      "500": "#358238",
      "600": "#2e6930",
      "700": "#1e4620",
      "800": "#0f2310",
      "900": "#09150a",
      "000": "#edf7ee",
    },
    neutral: {
      "100": "#121213",
      "200": "#1c1b1c",
      "250": "#242324",
      "300": "#2e2d2f",
      "350": "#3c3b3e",
      "400": "#5c5b5e",
      "500": "#8a888e",
      "600": "#a19fa5",
      "700": "#b8b6bd",
      "800": "#cfccd4",
      "900": "#e6e3ec",
      "1000": "#f3f1f6",
      "1100": "#faf9fb",
      "000": "#090a0f",
    },
    neutralAlpha: {
      "100": "#b8b6bd0f",
      "200": "#b8b6bd1a",
      "250": "#b8b6bd26",
      "300": "#b8b6bd33",
      "350": "#b8b6bd54",
      "400": "#b8b6bd78",
      "500": "#b8b6bdba",
      "600": "#b8b6bdd9",
      "700": "#b8b6bde6",
      "800": "#b8b6bdf2",
      "900": "#b8b6bd",
      "000": "#00000000",
    },
    gradient: {
      orangeYellow: "linear-gradient(90deg, #FF996C 0%, #FFF6B6 100%)",
      yellowPurple: "linear-gradient(90deg, #FFF6B6 0%, #A97DFF 100%)",
      bluePurple: "linear-gradient(90deg, #397BFF 0%, #A97DFF 100%)",
      yellowBlue: "linear-gradient(90deg, #FFF6B6 0%, #397BFF 100%)",
      orangeTeal: "linear-gradient(90deg, #FF996C 0%, #8DFCF1 99%)",
      orangePurple: "linear-gradient(90deg, #FF996C 0%, #A97DFF 100%)",
      blueOrange: "linear-gradient(90deg, #397BFF 0%, #FF996C 99%)",
      purpleTeal: "linear-gradient(90deg, #A97DFF 0%, #8DFCF1 99%)",
      tealBlue: "linear-gradient(90deg, #8DFCF1 0%, #397BFF 100%)",
      tealYellow: "linear-gradient(90deg, #8DFCF1 0%, #FFF6B6 100%)",
    },
    lime: {
      "100": "#e0ffbf",
      "200": "#c5ff8a",
      "300": "#abf560",
      "400": "#8fdd3c",
      "500": "#75c727",
      "600": "#5bab1d",
      "700": "#428714",
      "800": "#2d5f0e",
      "900": "#1a3a08",
      "000": "#f5ffe7",
    },
    magenta: {
      "100": "#ffd9f4",
      "200": "#ffa5e4",
      "300": "#ff74d1",
      "400": "#e95bc0",
      "500": "#d35ebf",
      "600": "#b34ba2",
      "700": "#8f3a82",
      "800": "#63275a",
      "900": "#41183b",
      "000": "#fff0fb",
    },
    gray: {
      "100": "#ededed",
      "200": "#e0e0e2",
      "300": "#d1d1d5",
      "400": "#b3b3b8",
      "500": "#96969c",
      "600": "#78787f",
      "700": "#5a5a60",
      "800": "#3d3d42",
      "900": "#27272a",
      "000": "#f9f9fa",
    },
  },
  text: {
    brand: "#397bff",
    inverse: "#090a0f",
    default: "#ffffff",
    discovery: "#ba97ff",
    information: "#6195ff",
    subtle: "#cfccd4",
    subtlest: "#a19fa5",
    danger: "#f44343",
    success: "#4caf50",
    warning: {
      default: "#fff8c5",
      inverse: "#f3f1f6",
    },
    disabled: "#5c5b5e",
    selected: "#173166",
    accent: {
      blue: {
        default: "#397bff",
        bolder: "#b0caff",
      },
      red: {
        default: "#f44343",
        bolder: "#feecec",
      },
      orange: {
        default: "#ff996c",
        bolder: "#ffd6c4",
      },
      yellow: {
        default: "#ffee70",
        bolder: "#fffbe2",
      },
      green: {
        default: "#4caf50",
        bolder: "#b7dfb9",
      },
      purple: {
        default: "#9871e6",
        bolder: "#ddcbff",
      },
      teal: {
        default: "#8dfcf1",
        bolder: "#d1fef9",
      },
      gray: {
        default: "#8a888e",
        bolder: "#cfccd4",
      },
      lime: {
        default: "#c5ff8a",
        bolder: "#e0ffbf",
      },
      magenta: {
        default: "#ffa5e4",
        bolder: "#ffd9f4",
      },
    },
  },
  link: {
    default: "#397bff",
    hovered: "#397bff",
    pressed: "#6195ff",
    visited: "#ba97ff",
  },
  icon: {
    brand: "#397bff",
    inverse: "#121213",
    danger: "#f44343",
    success: "#4caf50",
    warning: "#fff8c5",
    disabled: "#8a888e",
    accent: {
      blue: "#397bff",
      red: "#f44343",
      orange: "#ff996c",
      yellow: "#ffee70",
      green: "#4caf50",
      purple: "#9871e6",
      teal: "#7fe3d9",
      gray: "#5c5b5e",
      lime: "#8fdd3c",
      magenta: "#e95bc0",
    },
    default: "#ffffff",
    subtle: "#b8b6bd",
    discovery: "#9871e6",
    information: "#336fe6",
    selected: "#397bff",
  },
  interaction: {
    hovered: "#00000029",
    pressed: "#00000052",
  },
  border: {
    brand: "#397bff",
    bold: "#a19fa5",
    inverse: "#090a0f",
    focused: "#6195ff",
    information: "#336fe6",
    danger: "#f44343",
    success: "#4caf50",
    input: "#ffffff",
    disabled: "#b8b6bd78",
    discovery: "#9871e6",
    selected: "#397bff",
    default: "#ffffff",
    accent: {
      blue: "#397bff",
      red: "#f44343",
      orange: "#ff996c",
      yellow: "#ffee70",
      green: "#4caf50",
      purple: "#a97dff",
      teal: "#7fe3d9",
      gray: "#8a888e",
      magenta: "#e95bc0",
    },
    subtle: "#5c5b5e",
    table: {
      default: "#b8b6bd33",
      container: "#00000000",
    },
    warning: "#ed7c4a",
  },
  background: {
    brand: {
      subtlest: {
        default: "#070f1f",
        hovered: "#0c1933",
        pressed: "#173166",
      },
      bold: {
        default: "#397bff",
        hovered: "#6195ff",
        pressed: "#b0caff",
      },
      boldest: {
        default: "#ebf2ff",
        hovered: "#b0caff",
        pressed: "#6195ff",
      },
    },
    disabled: "#b8b6bd1a",
    discovery: {
      default: {
        "[default]": "#140f1f",
        hovered: "#221933",
        pressed: "#443266",
      },
      bold: {
        default: "#a97dff",
        hovered: "#ba97ff",
        pressed: "#ddcbff",
      },
    },
    information: {
      default: {
        "[default]": "#070f1f",
        hovered: "#0c1933",
        pressed: "#173166",
      },
      bold: {
        default: "#397bff",
        hovered: "#6195ff",
        pressed: "#b0caff",
      },
    },
    success: {
      default: {
        "[default]": "#09150a",
        hovered: "#0f2310",
        pressed: "#1e4620",
      },
      bold: {
        default: "#4caf50",
        hovered: "#b7dfb9",
        pressed: "#b7dfb9",
      },
    },
    danger: {
      default: {
        "[default]": "#310e0e",
        hovered: "#621b1b",
        pressed: "#922828",
      },
      bold: {
        default: "#f44343",
        hovered: "#f66969",
        pressed: "#fbb4b4",
      },
    },
    warning: {
      default: {
        "[default]": "#484117",
        hovered: "#90832f",
        pressed: "#c0ae3e",
      },
      bold: {
        default: "#fff6b6",
        hovered: "#fff8c5",
        pressed: "#fffbe2",
      },
    },
    accent: {
      blue: {
        subtlest: "#0c1933",
        subtler: "#224a99",
        subtle: "#2256c5",
        bolder: "#397bff",
      },
      red: {
        subtlest: "#310e0e",
        subtler: "#621b1b",
        subtle: "#922828",
        bolder: "#f44343",
      },
      orange: {
        subtlest: "#2f190f",
        subtler: "#8e4a2c",
        subtle: "#be633b",
        bolder: "#ff996c",
      },
      yellow: {
        subtlest: "#484117",
        subtler: "#c0ae3e",
        subtle: "#f0da4e",
        bolder: "#fff6b6",
      },
      green: {
        subtlest: "#0f2310",
        subtler: "#2e6930",
        subtle: "#358238",
        bolder: "#4caf50",
      },
      teal: {
        subtlest: "#1c3330",
        subtler: "#559791",
        subtle: "#71cac1",
        bolder: "#8dfcf1",
      },
      purple: {
        subtlest: "#221933",
        subtler: "#654b99",
        subtle: "#8764cc",
        bolder: "#a97dff",
      },
      gray: {
        subtlest: {
          default: "#1c1b1c",
          hovered: "#121213",
          pressed: "#090a0f",
        },
        subtler: {
          default: "#2e2d2f",
          hovered: "#1c1b1c",
          pressed: "#121213",
        },
        subtle: {
          default: "#8a888e",
          hovered: "#5c5b5e",
          pressed: "#2e2d2f",
        },
        bolder: {
          default: "#b8b6bd",
          hovered: "#a19fa5",
          pressed: "#8a888e",
        },
      },
      gradient: {
        orangeYellow: "linear-gradient(90deg, #FF996C 0%, #FFF6B6 100%)",
        yellowPurple: "linear-gradient(90deg, #FFF6B6 0%, #A97DFF 100%)",
        bluePurple: "linear-gradient(90deg, #397BFF 0%, #A97DFF 100%)",
        yellowBlue: "linear-gradient(90deg, #FFF6B6 0%, #397BFF 100%)",
        orangeTeal: "linear-gradient(90deg, #FF996C 0%, #8DFCF1 99%)",
        orangePurple: "linear-gradient(90deg, #FF996C 0%, #A97DFF 100%)",
        blueOrange: "linear-gradient(90deg, #397BFF 0%, #FF996C 99%)",
        purpleTeal: "linear-gradient(90deg, #A97DFF 0%, #8DFCF1 99%)",
        tealBlue: "linear-gradient(90deg, #8DFCF1 0%, #397BFF 100%)",
        tealYellow: "linear-gradient(90deg, #8DFCF1 0%, #FFF6B6 100%)",
      },
      magenta: {
        subtlest: "#fff0fb",
        subtler: "#ffd9f4",
        subtle: "#ff74d1",
        bolder: "#b34ba2",
      },
      lime: {
        subtlest: "#f5ffe7",
        subtler: "#e0ffbf",
        subtle: "#abf560",
        bolder: "#5bab1d",
      },
    },
    neutral: {
      default: {
        "[default]": "#b8b6bd1a",
        hovered: "#b8b6bd33",
        pressed: "#b8b6bd78",
      },
      subtle: {
        default: "#00000000",
        hovered: "#b8b6bd1a",
        pressed: "#b8b6bd33",
      },
      bold: {
        default: "#cfccd4",
        hovered: "#e6e3ec",
        pressed: "#f3f1f6",
      },
    },
    selected: {
      default: {
        "[default]": "#070f1f",
        hovered: "#0c1933",
        pressed: "#0c1933",
      },
      bold: {
        default: "#397bff",
        hovered: "#6195ff",
        pressed: "#b0caff",
      },
    },
    input: {
      default: "#121213",
      hovered: "#1c1b1c",
      pressed: "#121213",
    },
    inverse: {
      subtle: {
        default: "#ffffff29",
        hovered: "#ffffff3d",
        pressed: "#ffffff52",
      },
    },
  },
  blanket: {
    default: "#10121499",
    selected: "#1d7afc14",
    danger: "#e3493514",
  },
  skeleton: {
    default: "#b8b6bd54",
    subtle: "#b8b6bd0f",
  },
  elevation: {
    surface: {
      default: {
        "[default]": "#090a0f",
        hovered: "#1c1b1c",
        pressed: "#2e2d2f",
      },
      sunken: "#090a0f",
      raised: {
        default: "#1c1b1c",
        hovered: "#242324",
        pressed: "#2e2d2f",
      },
      overlay: {
        default: "#1c1b1c",
        hovered: "#2e2d2f",
        pressed: "#2e2d2f",
      },
    },
    shadow: {
      raised: "0.4pxpx 0.6pxpx 0.8pxpx 0px #000000ab, 2.2pxpx 3pxpx 4.4pxpx -2.1pxpx #000000c7",
      overlay: "0.4pxpx 0.6pxpx 0.8pxpx 0px #000000a1, 2.9pxpx 3.9pxpx 5.7pxpx -1.1pxpx #000000b3, 11.1pxpx 14.9pxpx 21.9pxpx -2.1pxpx #000000c2",
      overflow: {
        default: "0.4pxpx 0.6pxpx 0.8pxpx 0px #000000b3, 6.8pxpx 9.1pxpx 13.4pxpx -0.5pxpx #000000bd, 15.7pxpx 21.3pxpx 31.2pxpx -1.1pxpx #000000c9, 33.6pxpx 45.3pxpx 66.4pxpx -1.6pxpx #000000d6, 66.4pxpx 89.7pxpx 131.4pxpx -2.1pxpx #000000e1",
      },
    },
  },
  opacity: {
    disabled: 0.38,
    loading: 0.7,
  },
  chart: {
    brand: {
      default: "#336fe6",
      hovered: "#397bff",
    },
    neutral: {
      default: "#a19fa5",
      hovered: "#b8b6bd",
    },
    success: {
      default: {
        "[default]": "#449e48",
        hovered: "#4caf50",
      },
      bold: {
        default: "#b7dfb9",
        hovered: "#b7dfb9",
      },
    },
    danger: {
      default: {
        "[default]": "#c33636",
        hovered: "#dc3c3c",
      },
      bold: {
        default: "#f66969",
        hovered: "#fbb4b4",
      },
    },
    warning: {
      default: {
        "[default]": "#ffee70",
        hovered: "#fff6b6",
      },
      bold: {
        default: "#fff8c5",
        hovered: "#fffbe2",
      },
    },
    information: {
      default: {
        "[default]": "#2256c5",
        hovered: "#336fe6",
      },
      bold: {
        default: "#6195ff",
        hovered: "#b0caff",
      },
    },
    discovery: {
      default: {
        "[default]": "#8764cc",
        hovered: "#9871e6",
      },
      bold: {
        default: "#ba97ff",
        hovered: "#ddcbff",
      },
    },
    categorical: {
      "1": {
        default: "#71cac1",
        hovered: "#7fe3d9",
      },
      "2": {
        default: "#ba97ff",
        hovered: "#ddcbff",
      },
      "3": {
        default: "#be633b",
        hovered: "#ed7c4a",
      },
      "4": {
        default: "#ffa5e4",
        hovered: "#ffd9f4",
      },
      "5": {
        default: "#b0caff",
        hovered: "#ebf2ff",
      },
      "6": {
        default: "#8764cc",
        hovered: "#9871e6",
      },
      "7": {
        default: "#ffd9f4",
        hovered: "#fff0fb",
      },
      "8": {
        default: "#ffad89",
        hovered: "#ffd6c4",
      },
    },
    blue: {
      bold: {
        default: "#2256c5",
        hovered: "#336fe6",
      },
      bolder: {
        default: "#336fe6",
        hovered: "#397bff",
      },
      boldest: {
        default: "#6195ff",
        hovered: "#b0caff",
      },
    },
    red: {
      bold: {
        default: "#c33636",
        hovered: "#dc3c3c",
      },
      bolder: {
        default: "#dc3c3c",
        hovered: "#f44343",
      },
      boldest: {
        default: "#f66969",
        hovered: "#fbb4b4",
      },
    },
    orange: {
      bold: {
        default: "#ed7c4a",
        hovered: "#ff996c",
      },
      bolder: {
        default: "#ff996c",
        hovered: "#ffad89",
      },
      boldest: {
        default: "#ffad89",
        hovered: "#ffd6c4",
      },
    },
    yellow: {
      bold: {
        default: "#ffee70",
        hovered: "#fff6b6",
      },
      bolder: {
        default: "#fff6b6",
        hovered: "#fff8c5",
      },
      boldest: {
        default: "#fff8c5",
        hovered: "#fffbe2",
      },
    },
    green: {
      bold: {
        default: "#449e48",
        hovered: "#4caf50",
      },
      bolder: {
        default: "#4caf50",
        hovered: "#b7dfb9",
      },
      boldest: {
        default: "#b7dfb9",
        hovered: "#b7dfb9",
      },
    },
    teal: {
      bold: {
        default: "#7fe3d9",
        hovered: "#8dfcf1",
      },
      bolder: {
        default: "#8dfcf1",
        hovered: "#a4fdf4",
      },
      boldest: {
        default: "#a4fdf4",
        hovered: "#d1fef9",
      },
    },
    purple: {
      bold: {
        default: "#8764cc",
        hovered: "#9871e6",
      },
      bolder: {
        default: "#9871e6",
        hovered: "#a97dff",
      },
      boldest: {
        default: "#ba97ff",
        hovered: "#ddcbff",
      },
    },
    magenta: {
      bold: {
        default: "#d35ebf",
        hovered: "#e95bc0",
      },
      bolder: {
        default: "#e95bc0",
        hovered: "#ff74d1",
      },
      boldest: {
        default: "#ffa5e4",
        hovered: "#ffd9f4",
      },
    },
    gray: {
      bold: {
        default: "#8a888e",
        hovered: "#a19fa5",
      },
      bolder: {
        default: "#a19fa5",
        hovered: "#b8b6bd",
      },
      boldest: {
        default: "#b8b6bd",
        hovered: "#cfccd4",
      },
    },
    lime: {
      bold: {
        default: "#8fdd3c",
        hovered: "#abf560",
      },
      bolder: {
        default: "#abf560",
        hovered: "#c5ff8a",
      },
      boldest: {
        default: "#c5ff8a",
        hovered: "#e0ffbf",
      },
    },
  },
};
