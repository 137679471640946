/**
 * @fuegokit/tokens 0.23.0
 * Do not edit directly
 * Generated on Fri, 11 Oct 2024 13:40:08 GMT
 */
export default {
  scale: {
    gray: {
      "100": "#c7ced6",
      "200": "#b5bec9",
      "300": "#a3aebd",
      "400": "#929fb0",
      "500": "#6b778c",
      "600": "#505f79",
      "700": "#344563",
      "800": "#172b4d",
      "900": "#091e42",
      "000": "#fafbfc",
    },
    neutral: {
      "100": "#1d2125",
      "200": "#22272b",
      "250": "#282e33",
      "300": "#2c333a",
      "350": "#38414a",
      "400": "#454f59",
      "500": "#596773",
      "600": "#738496",
      "700": "#8c9bab",
      "800": "#9fadbc",
      "900": "#b6c2cf",
      "1000": "#c7d1db",
      "1100": "#dee4ea",
      "000": "#161a1d",
    },
    neutralAlpha: {
      "100": "#bcd6f00a",
      "200": "#a1bdd914",
      "250": "#c8e1f91a",
      "300": "#a6c5e229",
      "350": "#c3defe33",
      "400": "#bfdbf847",
      "500": "#9bb4ca80",
      "600": "#9bb4caa3",
      "700": "#9bb4cab0",
      "800": "#a4bfd7e3",
      "900": "#b4d0e9f7",
      "000": "#00000000",
    },
    blue: {
      "100": "#cce0ff",
      "200": "#85b8ff",
      "300": "#579dff",
      "400": "#388bff",
      "500": "#1d7afc",
      "600": "#0c66e4",
      "700": "#0055cc",
      "800": "#09326c",
      "900": "#1c2b41",
      "000": "#e9f2ff",
    },
    teal: {
      "100": "#c6edfb",
      "200": "#9dd9ee",
      "300": "#6cc3e0",
      "400": "#42b2d7",
      "500": "#2898bd",
      "600": "#227d9b",
      "700": "#206a83",
      "800": "#164555",
      "900": "#1e3137",
      "000": "#e7f9ff",
    },
    purple: {
      "100": "#dfd8fd",
      "200": "#b8acf6",
      "300": "#9f8fef",
      "400": "#8f7ee7",
      "500": "#8270db",
      "600": "#6e5dc6",
      "700": "#5e4db2",
      "800": "#352c63",
      "900": "#2b273f",
      "000": "#f3f0ff",
    },
    green: {
      "100": "#baf3db",
      "200": "#7ee2b8",
      "300": "#4bce97",
      "400": "#2abb7f",
      "500": "#22a06b",
      "600": "#1f845a",
      "700": "#216e4e",
      "800": "#164b35",
      "900": "#1c3329",
      "000": "#dcfff1",
    },
    yellow: {
      "100": "#f8e6a0",
      "200": "#f5cd47",
      "300": "#e2b203",
      "400": "#cf9f02",
      "500": "#b38600",
      "600": "#946f00",
      "700": "#7f5f01",
      "800": "#533f04",
      "900": "#332e1b",
      "000": "#fff7d6",
    },
    red: {
      "100": "#ffd5d2",
      "200": "#fd9891",
      "300": "#f87168",
      "400": "#f15b50",
      "500": "#e2483d",
      "600": "#c9372c",
      "700": "#ae2e24",
      "800": "#5d1f1a",
      "900": "#42221f",
      "000": "#ffeceb",
    },
    orange: {
      "100": "#fedec8",
      "200": "#fec195",
      "300": "#fea362",
      "400": "#f38a3f",
      "500": "#e56910",
      "600": "#c25100",
      "700": "#a54800",
      "800": "#702e00",
      "900": "#38291e",
      "000": "#fff3eb",
    },
    magenta: {
      "100": "#fdd0ec",
      "200": "#f797d2",
      "300": "#e774bb",
      "400": "#da62ac",
      "500": "#cd519d",
      "600": "#ae4787",
      "700": "#943d73",
      "800": "#50253f",
      "900": "#3d2232",
      "000": "#ffecf8",
    },
    lime: {
      "100": "#d3f1a7",
      "200": "#b3df72",
      "300": "#94c748",
      "400": "#82b536",
      "500": "#6a9a23",
      "600": "#5b7f24",
      "700": "#4c6b1f",
      "800": "#37471f",
      "900": "#28311b",
      "000": "#efffd6",
    },
  },
  elevation: {
    surface: {
      default: {
        "[default]": "#1d2125",
        hovered: "#1d2125",
        pressed: "#22272b",
      },
      sunken: "#161a1d",
      raised: {
        default: "#22272b",
        hovered: "#282e33",
        pressed: "#2c333a",
      },
      overlay: {
        default: "#22272b",
        hovered: "#2c333a",
        pressed: "#2c333a",
      },
    },
    shadow: {
      raised: "0px 1px 1px 0px #03040480, 0px 0px 1px 0px #03040480",
      overflow: {
        default: "0px 0px 12px 0px #0304048f, 0px 0px 1px 0px #03040480",
        spread: "#0304048f",
        perimeter: "#091e421f",
      },
      overlay: "0px 0px 0px 1px #bcd6f00a, 0px 8px 12px 0px #0304045c, 0px 0px 1px 0px #03040480",
    },
  },
  border: {
    default: "#a6c5e229",
    bold: "#738496",
    selected: "#579dff",
    focused: "#cce0ff",
    subtle: "#bfdbf847",
    input: "#a6c5e229",
    inverse: "#161a1d",
    disabled: "#a1bdd914",
    brand: "#579dff",
    danger: "#f15b50",
    warning: "#f38a3f",
    success: "#2abb7f",
    discovery: "#8f7ee7",
    information: "#388bff",
    accent: {
      blue: "#388bff",
      red: "#f15b50",
      orange: "#fea362",
      yellow: "#cf9f02",
      green: "#2abb7f",
      purple: "#8f7ee7",
      teal: "#42b2d7",
      magenta: "#da62ac",
      gray: "#738496",
    },
    table: {
      default: "#a6c5e229",
      container: "#00000000",
    },
  },
  background: {
    accent: {
      blue: {
        subtlest: "#1c2b41",
        subtler: "#09326c",
        subtle: "#0055cc",
        bolder: "#579dff",
      },
      red: {
        subtlest: "#42221f",
        subtler: "#5d1f1a",
        subtle: "#ae2e24",
        bolder: "#f87168",
      },
      orange: {
        subtlest: "#38291e",
        subtler: "#702e00",
        subtle: "#a54800",
        bolder: "#fea362",
      },
      yellow: {
        subtlest: "#332e1b",
        subtler: "#533f04",
        subtle: "#7f5f01",
        bolder: "#e2b203",
      },
      green: {
        subtlest: "#1c3329",
        subtler: "#164b35",
        subtle: "#216e4e",
        bolder: "#4bce97",
      },
      purple: {
        subtlest: "#2b273f",
        subtler: "#352c63",
        subtle: "#5e4db2",
        bolder: "#9f8fef",
      },
      teal: {
        subtlest: "#1e3137",
        subtler: "#164555",
        subtle: "#206a83",
        bolder: "#6cc3e0",
      },
      magenta: {
        subtlest: "#3d2232",
        subtler: "#50253f",
        subtle: "#943d73",
        bolder: "#e774bb",
      },
      gray: {
        subtlest: {
          default: "#2c333a",
          hovered: "#38414a",
          pressed: "#454f59",
        },
        subtler: {
          default: "#454f59",
          hovered: "#596773",
          pressed: "#738496",
        },
        subtle: {
          default: "#596773",
          hovered: "#454f59",
          pressed: "#38414a",
        },
        bolder: {
          default: "#8c9bab",
          hovered: "#9fadbc",
          pressed: "#b6c2cf",
        },
      },
      lime: {
        subtlest: "#28311b",
        subtler: "#37471f",
        subtle: "#4c6b1f",
        bolder: "#94c748",
      },
    },
    input: {
      default: "#1d2125",
      hovered: "#22272b",
      pressed: "#1d2125",
    },
    inverse: {
      subtle: {
        default: "#ffffff29",
        hovered: "#ffffff3d",
        pressed: "#ffffff52",
      },
    },
    neutral: {
      default: {
        "[default]": "#a1bdd914",
        hovered: "#a6c5e229",
        pressed: "#bfdbf847",
      },
      subtle: {
        default: "#00000000",
        hovered: "#a1bdd914",
        pressed: "#a6c5e229",
      },
      bold: {
        default: "#9fadbc",
        hovered: "#b6c2cf",
        pressed: "#c7d1db",
      },
    },
    brand: {
      subtlest: {
        default: "#1c2b41",
        hovered: "#09326c",
        pressed: "#0055cc",
      },
      bold: {
        default: "#579dff",
        hovered: "#85b8ff",
        pressed: "#cce0ff",
      },
      boldest: {
        default: "#e9f2ff",
        hovered: "#cce0ff",
        pressed: "#85b8ff",
      },
    },
    selected: {
      default: {
        "[default]": "#1c2b41",
        hovered: "#09326c",
        pressed: "#09326c",
      },
      bold: {
        default: "#579dff",
        hovered: "#85b8ff",
        pressed: "#cce0ff",
      },
    },
    disabled: "#a1bdd914",
    information: {
      default: {
        "[default]": "#1c2b41",
        hovered: "#09326c",
        pressed: "#0055cc",
      },
      bold: {
        default: "#579dff",
        hovered: "#85b8ff",
        pressed: "#cce0ff",
      },
    },
    danger: {
      default: {
        "[default]": "#42221f",
        hovered: "#5d1f1a",
        pressed: "#ae2e24",
      },
      bold: {
        default: "#f87168",
        hovered: "#fd9891",
        pressed: "#ffd5d2",
      },
    },
    success: {
      default: {
        "[default]": "#1c3329",
        hovered: "#164b35",
        pressed: "#216e4e",
      },
      bold: {
        default: "#4bce97",
        hovered: "#7ee2b8",
        pressed: "#baf3db",
      },
    },
    discovery: {
      default: {
        "[default]": "#2b273f",
        hovered: "#352c63",
        pressed: "#5e4db2",
      },
      bold: {
        default: "#9f8fef",
        hovered: "#b8acf6",
        pressed: "#dfd8fd",
      },
    },
    warning: {
      default: {
        "[default]": "#332e1b",
        hovered: "#533f04",
        pressed: "#7f5f01",
      },
      bold: {
        default: "#f5cd47",
        hovered: "#e2b203",
        pressed: "#cf9f02",
      },
    },
  },
  blanket: {
    default: "#10121499",
    selected: "#1d7afc14",
    danger: "#e3493514",
  },
  text: {
    default: "#c7d1db",
    subtle: "#9fadbc",
    subtlest: "#8c9bab",
    disabled: "#bfdbf847",
    selected: "#579dff",
    inverse: "#161a1d",
    success: "#7ee2b8",
    danger: "#fd9891",
    information: "#85b8ff",
    warning: {
      default: "#f5cd47",
      inverse: "#161a1d",
    },
    accent: {
      blue: {
        default: "#85b8ff",
        bolder: "#cce0ff",
      },
      red: {
        default: "#fd9891",
        bolder: "#ffd5d2",
      },
      orange: {
        default: "#fec195",
        bolder: "#fedec8",
      },
      yellow: {
        default: "#f8e6a0",
        bolder: "#f8e6a0",
      },
      green: {
        default: "#7ee2b8",
        bolder: "#baf3db",
      },
      purple: {
        default: "#b8acf6",
        bolder: "#dfd8fd",
      },
      teal: {
        default: "#9dd9ee",
        bolder: "#c6edfb",
      },
      magenta: {
        default: "#f797d2",
        bolder: "#fdd0ec",
      },
      gray: {
        default: "#9fadbc",
        bolder: "#c7d1db",
      },
      lime: {
        default: "#b3df72",
        bolder: "#d3f1a7",
      },
    },
    discovery: "#b8acf6",
    brand: "#579dff",
  },
  link: {
    default: "#579dff",
    hovered: "#579dff",
    pressed: "#85b8ff",
    visited: "#b8acf6",
  },
  icon: {
    accent: {
      blue: "#388bff",
      red: "#e2483d",
      orange: "#f38a3f",
      yellow: "#f5cd47",
      green: "#2abb7f",
      purple: "#8f7ee7",
      teal: "#42b2d7",
      magenta: "#da62ac",
      gray: "#738496",
      lime: "#82b536",
    },
    default: "#9fadbc",
    subtle: "#8c9bab",
    inverse: "#161a1d",
    disabled: "#bfdbf847",
    brand: "#579dff",
    selected: "#579dff",
    danger: "#f15b50",
    success: "#2abb7f",
    discovery: "#8f7ee7",
    information: "#388bff",
    warning: {
      default: "#f5cd47",
      inverse: "#161a1d",
    },
  },
  interaction: {
    hovered: "#00000029",
    pressed: "#00000052",
  },
  skeleton: {
    default: "#a1bdd914",
    subtle: "#bcd6f00a",
  },
  opacity: {
    disabled: 0.2,
    loading: 0.4,
  },
  chart: {
    brand: {
      default: "#388bff",
      hovered: "#579dff",
    },
    neutral: {
      default: "#738496",
      hovered: "#8c9bab",
    },
    success: {
      default: {
        "[default]": "#2abb7f",
        hovered: "#4bce97",
      },
      bold: {
        default: "#7ee2b8",
        hovered: "#baf3db",
      },
    },
    danger: {
      default: {
        "[default]": "#e2483d",
        hovered: "#f15b50",
      },
      bold: {
        default: "#fd9891",
        hovered: "#ffd5d2",
      },
    },
    warning: {
      default: {
        "[default]": "#cf9f02",
        hovered: "#e2b203",
      },
      bold: {
        default: "#f5cd47",
        hovered: "#f8e6a0",
      },
    },
    information: {
      default: {
        "[default]": "#1d7afc",
        hovered: "#388bff",
      },
      bold: {
        default: "#85b8ff",
        hovered: "#cce0ff",
      },
    },
    discovery: {
      default: {
        "[default]": "#8270db",
        hovered: "#8f7ee7",
      },
      bold: {
        default: "#b8acf6",
        hovered: "#dfd8fd",
      },
    },
    categorical: {
      "1": {
        default: "#2898bd",
        hovered: "#42b2d7",
      },
      "2": {
        default: "#b8acf6",
        hovered: "#dfd8fd",
      },
      "3": {
        default: "#e56910",
        hovered: "#f38a3f",
      },
      "4": {
        default: "#f797d2",
        hovered: "#fdd0ec",
      },
      "5": {
        default: "#cce0ff",
        hovered: "#e9f2ff",
      },
      "6": {
        default: "#8270db",
        hovered: "#8f7ee7",
      },
      "7": {
        default: "#fdd0ec",
        hovered: "#ffecf8",
      },
      "8": {
        default: "#fec195",
        hovered: "#fedec8",
      },
    },
    blue: {
      bold: {
        default: "#1d7afc",
        hovered: "#388bff",
      },
      bolder: {
        default: "#388bff",
        hovered: "#579dff",
      },
      boldest: {
        default: "#85b8ff",
        hovered: "#cce0ff",
      },
    },
    red: {
      bold: {
        default: "#e2483d",
        hovered: "#f15b50",
      },
      bolder: {
        default: "#f15b50",
        hovered: "#f87168",
      },
      boldest: {
        default: "#fd9891",
        hovered: "#ffd5d2",
      },
    },
    orange: {
      bold: {
        default: "#f38a3f",
        hovered: "#fea362",
      },
      bolder: {
        default: "#fea362",
        hovered: "#fec195",
      },
      boldest: {
        default: "#fec195",
        hovered: "#fedec8",
      },
    },
    yellow: {
      bold: {
        default: "#cf9f02",
        hovered: "#e2b203",
      },
      bolder: {
        default: "#e2b203",
        hovered: "#f5cd47",
      },
      boldest: {
        default: "#f5cd47",
        hovered: "#f8e6a0",
      },
    },
    green: {
      bold: {
        default: "#2abb7f",
        hovered: "#4bce97",
      },
      bolder: {
        default: "#4bce97",
        hovered: "#7ee2b8",
      },
      boldest: {
        default: "#7ee2b8",
        hovered: "#baf3db",
      },
    },
    teal: {
      bold: {
        default: "#42b2d7",
        hovered: "#6cc3e0",
      },
      bolder: {
        default: "#6cc3e0",
        hovered: "#9dd9ee",
      },
      boldest: {
        default: "#9dd9ee",
        hovered: "#c6edfb",
      },
    },
    purple: {
      bold: {
        default: "#8270db",
        hovered: "#8f7ee7",
      },
      bolder: {
        default: "#8f7ee7",
        hovered: "#9f8fef",
      },
      boldest: {
        default: "#b8acf6",
        hovered: "#dfd8fd",
      },
    },
    magenta: {
      bold: {
        default: "#cd519d",
        hovered: "#da62ac",
      },
      bolder: {
        default: "#da62ac",
        hovered: "#e774bb",
      },
      boldest: {
        default: "#f797d2",
        hovered: "#fdd0ec",
      },
    },
    gray: {
      bold: {
        default: "#596773",
        hovered: "#738496",
      },
      bolder: {
        default: "#738496",
        hovered: "#8c9bab",
      },
      boldest: {
        default: "#8c9bab",
        hovered: "#9fadbc",
      },
    },
    lime: {
      bold: {
        default: "#82b536",
        hovered: "#94c748",
      },
      bolder: {
        default: "#94c748",
        hovered: "#b3df72",
      },
      boldest: {
        default: "#b3df72",
        hovered: "#d3f1a7",
      },
    },
  },
};
